<template>
  <v-container class="my-6 mx-auto" style="max-width: 1500px">
    <v-row
      justify="start"
      v-if="ownAssignments && ownAssignments.length > 0"
      style="margin-top: 300px"
    >
      <v-col
        lg="6"
        md="6"
        cols="12"
        style="margin-top: -300px"
        v-for="assignment in ownAssignments"
        :key="'mysoriluud' + assignment.id"
      >
        <ReportSchool
          v-if="assignment.results && assignment.results.length > 0"
          :asuulga="assignment"
          :results="assignment.results"
        ></ReportSchool>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import ReportSchool from "@/views/Pages/Analyzes/ReportSchool.vue";

export default {
  components: { ReportSchool },
  data: () => ({
    downloadLoading: false,
    rules: {
      required: (value) => !!value || "Шаардлагатай!",
      required1: (value) => !!value || "Шалгалтын төрөл сонгоx!",
      required2: (value) => !!value || "Xамраx xүрээ сонгоx!",
    },
    messageNotification: null,
    newDialog: false,
    editedItem: {},
    defaultItem: {
      name: "",
      description: "",
    },
    query: null,
    headerNames: null,
    bodyNames: null,
    school: null,
    allLessons: null,
    classLessons: null,
    addd: false,

    allAssignments: null,
    schoolAssignments: null,
    ownAssignments: null,

    selectedAssignmentType: null,
    assignmentsTypes: [
      // { value: 1, name: "Заавал" },
      // { value: 2, name: "Сонгон" },
      { value: 2, name: "Түлxүүр үгтэй шалгалт" },

      // { value: 1, name: "Стандарт" },
    ],
    lessonCategories: null,
    lessonCategories2: null,
  }),
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    zzschool() {
      return this.userData ? this.userData.ppschool : null;
    },
    assignmentsDatabasePath() {
      // if (this.zzschool) return this.zzschool + "/assignmentsdatabase";
      // else
      return "assignmentsdatabase";
    },

    otherAssignments() {
      if (this.assignments)
        return this.assignments.filter(
          (el) => el.createdByEmail != (this.userData && this.userData.email)
        );
      else return null;
    },
  },
  created() {
    this._setupp();
  },
  methods: {
    _detail(assign) {
      console.log(assign);
    },
    _createAssignmentDatabase(item) {
      this.newDialog = true;
      if (item == null) {
        this.editedIndex = -1;
      }
    },
    async saveItem() {
      if (
        this.editedItem.name &&
        this.editedItem.name.trim() !== "" &&
        this.editedItem.selectedLessonCategory &&
        this.editedItem.examScope
      ) {
        console.log("saving...");
        if (!this.editedItem.duration) this.editedItem.duration = 0;
        this.editedItem.finalScore = 0;
        this.editedItem.categoryRef =
          this.editedItem.selectedLessonCategory.ref;

        this.editedItem.categoryRefPath =
          this.editedItem.selectedLessonCategory.ref.path;

        console.log(
          this.editedItem.schoolIndex,
          this.userData.school.schoolIndex
        );
        //this.editedItem.selectedAssignmentType = selectedAssignmentType;
        if (this.editedIndex === -1) {
          var x = await this.userData.ref.get();
          var firstName = null;
          if (x.data().firstName) {
            firstName = x.data().firstName;
          }
          if (this.zzschool != null) {
            console.log(this.zzschool);
            console.log(this.editedItem);
            var x2 = await fb.db.doc(this.zzschool).get();
            if (x2.exists) {
              this.editedItem.createdBySchoolName = x2.data().name;
              this.editedItem.createdBySchoolRef = x2.ref;

              if (this.editedItem.forAllSchool == undefined)
                this.editedItem.forAllSchool = false;
            }
          } else {
            this.editedItem.createdBySchoolName = null;
            this.editedItem.createdBySchoolRef = null;
            this.editedItem.forAllSchool = true;
          }
          this.editedItem.assignmentType = this.assignmentsTypes[0].value;
          this.editedItem.deleted = false;
          this.editedItem.setupped = false;
          this.editedItem.finished = false;
          this.editedItem.showingCorrectAnswers = false;
          this.editedItem.createdByRef = this.userData.ref;
          this.editedItem.createdAt = new Date();
          this.editedItem.createdByEmail = this.userData.email;
          this.editedItem.createdByFirstName = firstName;
          this.editedItem.createdByRole = this.userData.role;

          this.editedItem.currentYear = new Date().getFullYear();
          this.editedItem.currentMonth = new Date().getMonth() + 1;

          this.editedItem.schoolIndex = this.userData.school.schoolIndex
            ? this.userData.school.schoolIndex
            : null;
          if (this.userData.school) {
            this.userData.school.ref
              .collection("assignmentsdatabase")
              .doc()
              .set(this.editedItem)
              .then(() => {
                console.log("Document xxxxx written!");
                console.log(this.editedItem);
              })
              .catch((error) => {
                console.error("Error writing document: ", error);
              });
          } else {
            fb.db
              .collection("assignmentsdatabase")
              .doc()
              .set(this.editedItem)
              .then(() => {
                console.log("Document xxxxx written!");
              })
              .catch((error) => {
                console.error("Error writing document: ", error);
              });
          }
        } else {
          console.log(this.editedItem.categoryRef);
          this.editedItem.modifiedAt = new Date();
          this.editedItem.ref.update(this.editedItem);
        }
        this._closeEdit();
      } else {
        // this.messageNotification =
        //   "Доод тал нь <<нэр>> талбарыг бөглөж xадгалаx боломжтой";
      }
    },
    _closeEdit() {
      this.newDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async _setupp() {
      var counter = 0;
      fb.db
        .collection("categories")
        .orderBy("name", "asc")
        .onSnapshot((docs) => {
          this.lessonCategories = [];
          docs.forEach((doc) => {
            let item = doc.data();
            item.id = doc.id;
            item.ref = doc.ref;
            item.name2 =
              ++counter + ". " + item.name + " - " + item.lessonTypeName;
            this.lessonCategories.push(item);
          });
        });

      if (this.userData && this.userData.school) {
        this.userData.school.ref
          .collection("categories")
          .orderBy("name", "asc")
          .onSnapshot((docs) => {
            this.lessonCategories2 = [];
            docs.forEach((doc) => {
              let item = doc.data();
              item.id = doc.id;
              item.ref = doc.ref;
              item.name2 =
                ++counter + ". " + item.name + " - " + item.lessonTypeName;
              this.lessonCategories2.push(item);
            });
          });
      }

      fb.db
        .collectionGroup(this.assignmentsDatabasePath)
        .where("examScope.sorilIndex", "==", 1)
        .where("schoolIndex", "==", this.userData.schoolIndex)
        .where("deleted", "==", false)
        .orderBy("createdAt", "asc")
        .onSnapshot((docs) => {
          this.ownAssignments = [];

          docs.forEach(async (doc) => {
            let assign = doc.data();
            assign.id = doc.id;
            assign.ref = doc.ref;

            await assign.ref
              .collection("results-simplified")
              .orderBy("totalScore", "desc")
              .onSnapshot(async (docs) => {
                assign.results = [];
                var counter = 1;
                docs.forEach((doc) => {
                  let result = doc.data();
                  result.ref = doc.ref;
                  result.id = doc.id;
                  result.index = counter;

                  var totalScore = 0;
                  for (var category of result.categories) {
                    if (category.totalScore) {
                      totalScore = totalScore + category.totalScore;
                    }
                  }

                  result.percent =
                    ((result.totalScore / totalScore) * 100).toFixed(0) + "%";

                  assign.results.push(result);
                  counter++;
                });
              });
            setTimeout(() => {
              this.ownAssignments.push(assign);
            }, 1000);
          });
          console.log(this.ownAssignments);
        });
    },
  },
};
</script>
